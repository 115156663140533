<template>
    <div>
        <div class="work page-wrapper" v-if="dataLoaded">
            <div
                class="d-flex flex-column justify-content-center position-relative overflow-hidden"
            >
                <ShapePicture class="shape-01" name="work/Shape-01" />
                <ShapePicture class="shape-02" name="work/Shape-02" />
                <div class="py-6 py-xl-7">
                    <div
                        class="container-longer container-fluid text-white mb-2 mb-lg-5 mb-xl-6"
                    >
                        <div class="row text-center">
                            <h1 class="col-12">
                                {{ object.acf.title }}
                            </h1>
                            <div
                                class="col-12 text-bigger"
                                v-html="object.acf.text"
                            ></div>
                        </div>
                    </div>
                    <TwoColumnsText
                        :title="object.acf.intro_title"
                        :text="object.acf.intro_description"
                        :hideTitleOnMobile="true"
                    >
                    </TwoColumnsText>
                </div>
            </div>

            <div
                class="filters sticky-nav bg-secondary text-white d-flex align-items-center"
                :class="[
                    navState ? 'z-index-0' : '',
                    filtersOpened ? 'z-index-1031' : 'z-index-1029'
                ]"
            >
                <div class="container-fluid text-center">
                    <transition name="fade">
                        <div class="nav-filters" v-if="filtersOpened">
                            <div class="container-longer">
                                <div
                                    class="hamburger hamburger--slider is-active d-flex justify-content-end align-items-center d-lg-none"
                                >
                                    <div
                                        class="hamburger-box"
                                        @click="closeFilters"
                                    >
                                        <div class="hamburger-inner"></div>
                                    </div>
                                </div>
                                <div
                                    class="nav-content d-flex flex-column flex-lg-row align-items-center justify-content-lg-center pt-6 pt-lg-0"
                                >
                                    <h6
                                        v-for="(category, id) in mainCategories"
                                        :key="id"
                                        @click="applyFilters(category.id)"
                                        class="filter-text font-weight-bold text-white py-3 py-lg-0 my-lg-0 mx-3 text-uppercase cursor-pointer"
                                        :class="
                                            activeID === category.id
                                                ? 'active'
                                                : ''
                                        "
                                    >
                                        {{ category.name }}
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </transition>

                    <mq-layout :mq="['xs', 'sm', 'md']">
                        <h5
                            @click="openFilters"
                            class="d-flex align-items-center justify-content-center text-uppercase"
                        >
                            Filter
                        </h5>
                    </mq-layout>
                </div>
            </div>

            <transition name="fade">
                <div class="projects-list" v-if="filtered">
                    <Projects :projects="filteredProjects" />
                </div>
            </transition>
        </div>
        <Footer />
    </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import seoMixin from "@/mixins/seoMixin";
import Projects from "../components/Projects.vue";
import TwoColumnsText from "../components/TwoColumnsText.vue";
import ShapePicture from "../components/ShapePicture.vue";
import Footer from "../components/Footer.vue";

export default {
    name: "work",
    mixins: [seoMixin],
    components: {
        Projects,
        TwoColumnsText,
        ShapePicture,
        Footer
    },
    data() {
        return {
            object: {},
            dataLoaded: false,
            filtersOpened: false,
            filtered: true, //samo zbog fade animacije kad se klikne na neki filter
            activeID: 0 //id aktivnog filtera
        };
    },
    computed: {
        ...mapGetters(["navState", "mainCategories", "categories", "projects"]),

        // filtrirani projekti - mjenjaju se kad se promjeni activeID
        filteredProjects() {
            if (this.activeID === 0) {
                return this.projects.filter(project => {
                    // radimo sa projektima koji nemaju is_hidden property ili ga imaju ali je false
                    if (
                        !("is_hidden" in project.acf) ||
                        project.acf.is_hidden === false
                    ) {
                        return project;
                    }
                });
            }
            return this.projects.filter(project => {
                // radimo sa projektima koji nemaju is_hidden property ili ga imaju ali je false
                if (
                    !("is_hidden" in project.acf) ||
                    project.acf.is_hidden === false
                ) {
                    // prikaži sve projekte koji pripadaju nekoj kategoriji
                    return project.categories.includes(this.activeID);
                }
            });
        }
    },
    watch: {
        $mq(newVal, oldVal) {
            // ako nije mobile -> prikaži filtere, ako je došlo do promjene viewport-a
            newVal !== "xs" && newVal !== "sm" && newVal !== "md"
                ? (this.filtersOpened = true)
                : (this.filtersOpened = false);
        }
    },
    methods: {
        goToPage(e, slug) {
            e.preventDefault();
            if ($("html").hasClass("touch")) {
                e.preventDefault();
                setTimeout(() => {
                    this.$router.push({
                        name: "work-detail",
                        params: { slug: slug }
                    });
                }, 1000); // čekamo prije prelaska na stranicu kratko vrijeme da se ispiše ime projekta na koji se kliknulo
            } else {
                this.$router.push({
                    name: "work-detail",
                    params: { slug: slug }
                });
            }
        },
        openFilters() {
            this.$mq === "xs" || this.$mq === "sm" || this.$mq === "md"
                ? (this.filtersOpened = true)
                : "";
        },
        applyFilters(id) {
            let vm = this;
            vm.filtered = false; //sakrij trenutne projekte
            vm.activeID = id;
            vm.closeFilters();
            setTimeout(() => {
                vm.filtered = true; //prikaži filtrirane projekte - fade animacija
            }, 400);
        },
        closeFilters() {
            this.$mq === "xs" || this.$mq === "sm" || this.$mq === "md"
                ? (this.filtersOpened = false)
                : "";
        },
        stickyCallback(obj) {
            if (obj.sticked) {
                document.querySelector(".sticky-nav").classList.add("fixed");
            } else {
                document.querySelector(".sticky-nav").classList.remove("fixed");
                document
                    .querySelector(".sticky-nav")
                    .classList.add("position-relative");
            }
        }
    },
    created() {
        const pageApi = "wp-json/wp/v2/pages/851?_fields=id,slug,parent,acf";
        const data = axios.get(pageApi).then(res => {
            this.object = res.data;
            // from seoMixin
            this.setSeo(
                this.object.acf.seo_title,
                this.object.acf.seo_description,
                "https://www.wearealive.com/WAA_default.jpeg",
                "https://www.wearealive.com" + this.$route.fullPath
            );
            this.dataLoaded = true;
            document.dispatchEvent(new Event("custom-render-trigger"));
            setTimeout(() => {
                $("body").css("visibility", "unset");
                this.$store.dispatch("setLoading", false);
            }, loadingDelay);
        });

        // uzimanje query stringa i traženje kategorije prema tom slugu
        let queryCategory;
        queryCategory = this.categories.filter(category => {
            return category.slug === this.$route.query.filter;
        });
        // nakon što se pronađe kategorija, postavlja se njen ID kao activeID
        this.activeID = queryCategory[0].id;

        // na 1. load da li da prikaže filtere ili ne
        this.$mq === "xs" || this.$mq === "sm" || this.$mq === "md"
            ? (this.filtersOpened = false)
            : (this.filtersOpened = true);
    }
};
</script>
